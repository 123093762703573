/*
*
* Rules Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BIRTHDATE_FORMAT, BYS_URL, DATES, DATE_FORMAT } from 'components/AppRoot/Navigation/constants';
import dayjs from 'dayjs';

import './styles.css';

const select = (state) => ({
  startDatePretty: dayjs(state.params.contestDates[DATES.StartDate]).format(DATE_FORMAT),
  endDatePretty: dayjs(state.params.contestDates[DATES.EndDate]).format(DATE_FORMAT),
  drawDatePretty: dayjs(state.params.contestDates[DATES.DrawingDate]).format(DATE_FORMAT),
  winnerDatePretty: dayjs(state.params.contestDates[DATES.WinnerDate]).format(DATE_FORMAT),
  currentYear: state.params.contestDates.currentYear,
  birthDatePretty: dayjs(state.params.contestDates.birthDate).format(BIRTHDATE_FORMAT),
  librariesByRegions: state.params.librariesByRegions,
});

export function Rules({ startDatePretty, drawDatePretty, winnerDatePretty, currentYear, librariesByRegions, birthDatePretty, endDatePretty }) {
  return (
    <div className='Rules_container'>
      <h1>{currentYear} Book Your Summer Award</h1>
      <h2>Official Rules</h2>

      <p><b>Sponsor.</b> The sponsor is my529, located at the Utah Board of Higher Education Building, Gateway 2, 60 South 400 West, Salt Lake City, Utah, 84101-1284.</p>

      <p><b>How to enter.</b> Enter online at {BYS_URL} between {startDatePretty}, and {endDatePretty}. All entries must be submitted at {BYS_URL} by 11:59 p.m. MT, {endDatePretty}.</p>

      <p><b>No purchase necessary.</b> Purchase is not required to enter; opening a my529 account will not increase your chances of being selected for the award.</p>

      <p><b>Selection of award recipients.</b> On or about {drawDatePretty}, my529 will randomly select, in a process overseen by a representative of the Utah State Library, a total of six (6) award recipients—one each from six (6) predetermined regions of Utah as set forth in Attachment A [Award Regions]. The award recipients will each receive a $1,000 my529 college savings scholarship account.</p>

      <p>Award recipients will include one entrant per region.</p>

      <p>Recipients, and their parents or legal guardians, will be notified by telephone or email by {winnerDatePretty}. All participants agree to be held to the Official Contest Rules and decisions of my529, which are final and binding in all respects.</p>

      <p><b>Eligibility.</b> Eligibility for the award is limited to:</p>
      <ul>
        <li>Utah residents with a valid U.S. Social Security Number or Taxpayer Identification Number.</li>
        <li>Participants born between {birthDatePretty} through {endDatePretty}.</li>
        <li>Participants in the {currentYear} summer reading program* at their local public library.</li>
      </ul>
      <p>* For information about your library’s summer reading program, please contact your local library or visit <a href='https://library.utah.gov/directory/' target='_blank' rel='noopener noreferrer'>https://library.utah.gov/directory/</a> to search Utah public library locations.</p>
      <p>NOTE: The following individuals are ineligible to enter the contest:</p>
      <ul>
        <li>
          Employees, directors and agents of the Utah Board of Higher Education, Utah Education Savings Board of Trustees, my529, Utah State Library, and their respective affiliates, parents, subsidiaries and advertising agencies; and the immediate family members of all such people (parents, children, siblings, stepparents, stepsiblings, grandparents, grandchildren or spouse).
        </li>
      </ul>

      <p><b><u>You do not need to own a my529 account to be eligible for the award.</u></b></p>

      <p><b>Restrictions.</b> Limit one entry per eligible child. Each recipient may be awarded only one scholarship account. The my529 scholarship account may not be used in conjunction with, or as a match for, any other my529 promotion.</p>

      <p><b>Disclosure of identifying information.</b> A recipient’s first name and local library may be displayed on the my529 website (my529.org). Any other personal information, such as a last name or phone number, is used solely for my529’s internal purposes. By entering, each participant accepts that the participant’s phone number and/or email address may be used by my529 for my529 notifications.</p>

      <p><b>Disqualification.</b> Entries will be disqualified if they do not meet the rules and guidelines as set forth in this document, including:</p>
      <ul>
        <li>Entries not submitted electronically through the official online entry page.</li>
        <li>Entries that do not provide required contact information.</li>
        <li>Participants who are not born between {birthDatePretty} and {endDatePretty}.</li>
        <li>Entries submitted multiple times for a single child; however, a parent may submit one entry for each of their children who qualifies and participates in the designated library summer reading program.</li>
        <li>Previous winners of Book Your Summer. </li>
      </ul>

      <p><b>Information.</b> With a scholarship account, the principal and any related earnings must be spent only for qualified scholarship expenses. Any earnings should not be subject to federal or Utah state income taxes. However, it is possible that tax law could change, causing a scholarship account to be taxable, or that the Internal Revenue Service may rule that a scholarship account is taxable in the year it is awarded or distributed. Participants should consult their own tax advisor on the consequences to them of accepting an award. my529 does not provide tax or financial advice. Payment of all federal, state and local taxes, if any, that result from the {currentYear} Book Your Summer Award is the exclusive responsibility of the recipient. All federal, state and local laws apply. Odds of being awarded a scholarship account will depend upon number of qualified entries received during the eligible time period. The award is void where prohibited by law.</p>

      <p><b>Scholarship accounts.</b> For my529 to open a scholarship account, the recipients, parents or guardians must furnish beneficiary information to my529 no later than three months after the award date. Scholarship accounts may not be converted to cash and may not be used for nonqualified withdrawals of funds.</p>

      <p>Scholarship accounts are owned by my529 and invested in my529 Target Enrollment Date investment option Enrolled portfolio. Funds in a my529 scholarship account may be used to pay for tuition, fees, required equipment, and books and supplies. <b>Beneficiaries may not use scholarship account funds for room and board, qualified education loan repayments, computers and related equipment, internet service, registered apprenticeships, or K-12 tuition expenses.</b> Scholarship account funds may be used at any eligible educational institution—any college, university or technical school in the United States or abroad that is qualified to participate in federal student aid programs, whether in Utah, out of state, or abroad. (Visit <a href='https://studentaid.gov/' target='_blank' rel='noopener noreferrer'>https://studentaid.gov/</a> for a list of eligible schools.)</p>

      <p>Withdrawals from a scholarship account will be sent only to the eligible educational institution designated on the withdrawal form. my529 reserves the right to request documentation to verify that withdrawn funds were used for certain qualified scholarship expenses. The beneficiary must exhaust the funds in the scholarship account before the beneficiary reaches age 22. After the beneficiary reaches age 22, any remaining scholarship funds will be forfeited.</p>

      <p><b>my529 Investment Accounts.</b> Additional money from a recipient’s parents, guardians, friends, family members, or any other source may not be contributed to a scholarship account; however, other individual my529 accounts for the beneficiary may be opened where such contributions may be received. Each account owner or contributor should carefully read the my529 Program Description for more information and consider all investment objectives, risks, charges and expenses before investing. Investments are not guaranteed by any federal or state agency. An investment could lose value. However, Federal Deposit Insurance Corporation (FDIC) insurance is provided for the FDIC-insured accounts. The Program Description includes more information about the FDIC-insured accounts.</p>

      <p><b>Official contest rules and list of recipients.</b> These are the Official Rules of my529’s {currentYear} Book Your Summer Award. For a list of {currentYear} recipients, go to {BYS_URL}. The list of recipients will be posted online after the recipients have been notified. </p>

      <br />
      <br />

      <h3>Attachment A</h3>
      <h3><u>Award Regions</u></h3>
      {librariesByRegions.map(librariesByRegion => (
        <div key={librariesByRegion.region}>
          <h5><b><u>Region {librariesByRegion.region}</u></b> </h5>
          <ul>
            {librariesByRegion.libraries.map(libName => <li key={`${librariesByRegion.region}_${libName}`}>{libName}</li>)}
          </ul>
        </div>
      ))}
    </div>
  );
}

Rules.propTypes = {
  startDatePretty: PropTypes.string.isRequired,
  winnerDatePretty: PropTypes.string.isRequired,
  drawDatePretty: PropTypes.string.isRequired,
  currentYear: PropTypes.string.isRequired,
  birthDatePretty: PropTypes.string.isRequired,
  endDatePretty: PropTypes.string.isRequired,
  librariesByRegions: PropTypes.arrayOf(PropTypes.shape({
    region: PropTypes.number.isRequired,
    libraries: PropTypes.arrayOf(PropTypes.string.isRequired),
  }))
};

export default connect(select, {})(Rules);
/*
*
* Signup Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  SIGNUP_FORM_SUBMIT
} from './constants';

const initialState = {
  signupFormErrors: [],
};

function SignupReducer(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case SIGNUP_FORM_SUBMIT: {
      if (action.payload.data.Messages.$values.length > 0) {
        newState.signupFormErrors = action.payload.data.Messages.$values;
        return newState;
      }
      else {
        newState.signupFormErrors = [];
        return newState;
      }
    }
    
    default:
      return state;
  }

}

export default SignupReducer;
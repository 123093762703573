/*
*
* Home Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import library_logo from './images/library_logo.png';
import bookmarks from './images/bookmarkfan_032224.jpg';
import bysLogo from './images/bys_logo_vert.svg';
import my529LogoWithTagline from './images/my529_logo_Utah_tagline.jpg';
import { Button } from '@mui/material';
import { BIRTHDATE_FORMAT, DATES, PHASES, SHORT_DATE_FORMAT } from 'components/AppRoot/Navigation/constants';
import dayjs from 'dayjs';

import './styles.css';

const select = (state) => ({
  // currentPhase: state.params.currentPhase,
  currentPhase: state.params.currentPhase,
  startDatePretty: dayjs(state.params.contestDates[DATES.StartDate]).format(SHORT_DATE_FORMAT),
  endDatePretty: dayjs(state.params.contestDates[DATES.EndDate]).format(SHORT_DATE_FORMAT),
  winners: state.params.winners,
  birthDatePretty: dayjs(state.params.contestDates.birthDate).format(BIRTHDATE_FORMAT),
  visitYear: state.params.contestDates.visitYear,
});

export class Home extends React.Component {
  static propTypes = {
    currentPhase: PropTypes.string.isRequired,
    startDatePretty: PropTypes.string.isRequired,
    endDatePretty: PropTypes.string.isRequired,
    birthDatePretty: PropTypes.string.isRequired,
    visitYear: PropTypes.string.isRequired,
    winners: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      libraryName: PropTypes.string,
      libraryRegion: PropTypes.number,
    })),
  };

  textGenerate = () => {
    const {
      currentPhase,
      startDatePretty,
      endDatePretty,
      birthDatePretty,
      winners,
      visitYear,
    } = this.props;

    switch (currentPhase) {
      case PHASES.Teaser:
        return [
          <h1 key='startDate'>Going live {startDatePretty}</h1>,
          <p key='p1'><b>Six scholarship opportunities</b> for Utah K-12 students.</p>
        ];

      case PHASES.Start:
        return [
          <div key='signUpButton' className='Home_button'>
            <Button
              color='primary'
              onClick={() => this.props.history.push('/signup')}
              variant='contained'
              style={{ width: '250px' }}
            >
              Sign up here
            </Button>
          </div>,
          <p key='p1'>Register for the summer reading program at your local library. Then enter for a chance at one of <b>six</b> regional <strong style={{ color: 'var(--accent)' }}>$1,000</strong> my529 scholarships.</p>,
          <p key='p2'>One entry per reader, age 18 and under (born on or after {birthDatePretty}). <b>No purchase necessary to enter.</b></p>,
          <p key='p3'>Book Your Summer runs {startDatePretty} through {endDatePretty}.</p>,
        ];

      case PHASES.End:
        return [
          <p key='p1'>Thank you for your participation. Book Your Summer is now closed. Please check back next month for a list of the winners.</p>
        ];

      case PHASES.Winner:
        return [
          <h1 key='congratulations' style={{ textAlign: 'center' }}>Congratulations!</h1>,
          <div key='winners' className='Home_winners'>
            {winners.map(winner => (
              <span key={winner.name}>
                <strong>{winner.name}</strong> - {winner.libraryName}
                <small> (Region {winner.libraryRegion})</small>
              </span>
            ))}
          </div>,
          <p key='visitAgain' style={{ textAlign: 'center' }}>Visit us again in May {visitYear}!</p>
        ];

      default: return '';

    }
  }

  render() {
    return (
      <div className='Home_container'>
        <div className='Home_main'>
          <img src={bookmarks} alt='Bookmarks' className='Home_bookmarksImg' />
          <img src={bysLogo} alt='Book Your Summer' className='Home_bysLogoImg' />
        </div>

        <div className='Home_text'>
          {this.textGenerate()}
        </div>
        <div className='Home_logos'>
          <a href='https://library.utah.gov' target='_blank' rel='noopener noreferrer'>
            <img src={library_logo} alt='Utah State Library' />
          </a>
          <a href='https://my529.org' target='_blank' rel='noopener noreferrer'>
            <img src={my529LogoWithTagline} alt='my529' />
          </a>
        </div>
      </div>
    );
  }
}

export default connect(select, {})(Home);
/*
*
* FAQs Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BIRTHDATE_FORMAT, BYS_URL, DATES, DATE_FORMAT } from 'components/AppRoot/Navigation/constants';
import dayjs from 'dayjs';

import './styles.css';

const select = (state) => ({
  startDatePretty: dayjs(state.params.contestDates[DATES.StartDate]).format(DATE_FORMAT),
  endDatePretty: dayjs(state.params.contestDates[DATES.EndDate]).format(DATE_FORMAT),
  drawDatePretty: dayjs(state.params.contestDates[DATES.DrawingDate]).format(DATE_FORMAT),
  winnerDatePretty: dayjs(state.params.contestDates[DATES.WinnerDate]).format(DATE_FORMAT),
  birthDatePretty: dayjs(state.params.contestDates.birthDate).format(BIRTHDATE_FORMAT),
  currentYear: state.params.contestDates.currentYear,
  libraryPromoDatePretty: dayjs(state.params.contestDates[DATES.LibraryPromotionDate]).format(DATE_FORMAT),
});

export function FAQs({ birthDatePretty, startDatePretty, endDatePretty, drawDatePretty, winnerDatePretty, currentYear, libraryPromoDatePretty }) {

  return (
    <div className='FAQs_container'>
      <h1>{currentYear} Book Your Summer Award</h1>
      <h2>Sponsored by my529 and the Utah State Library</h2>
      <h3>Library FAQ</h3>

      <div className='FAQs_qAndA'>
        <div>
          <div>
            <h4>Q What is my529?</h4>
            <p><strong>A</strong> my529, Utah’s educational savings plan, provides a tax-advantaged investment vehicle designed to help families save for qualified education expenses. Visit <a href='https://my529.org' target='_blank' rel='noopener noreferrer'>my529.org</a> for more information.</p>
          </div>
          <div>
            <h4>Q How does the {currentYear} Book Your Summer Award work?</h4>
            <p><strong>A</strong> Parents and/or guardians of children who participate in their local library’s summer reading program can enter their child for a chance to be awarded a $1,000 my529 scholarship account. It’s easy to register at {BYS_URL}. Children ages 18 and under (born on or after {birthDatePretty}) are eligible for the award. Entries must be received between {startDatePretty}, and {endDatePretty}, at 11:59 p.m. MT.</p>

            <p><strong>There is no purchase necessary to participate.</strong></p>
          </div>
          <div>
            <h4>Q Do libraries benefit?</h4>
            <p><strong>A</strong> Yes. The Book Your Summer Award was established in 2016 as a way to help libraries promote and increase participation in their summer library reading programs. The {currentYear} Book Your Summer Award has the potential to continue helping to increase summer library program participation and library visits.</p>
          </div>
          <div>
            <h4>Q What information is needed to enter?</h4>
            <p><strong>A</strong> Parents and/or guardians must indicate on the Book Your Summer website that their child is participating in the summer reading program, choose their local library from a drop-down menu and provide contact information including the child’s name, address and birth date.</p>
          </div>
          <div>
            <h4>Q Why do entries need a birth date?</h4>
            <p><strong>A</strong> To verify age eligibility.</p>
          </div>
          <div>
            <h4>Q What is the award?</h4>
            <p><strong>A</strong> Recipients will receive a $1,000 my529 scholarship account. There will be a total of six award recipients, one each from six predetermined regions throughout Utah. Recipients will not receive cash. See list of regions and libraries in the Book Your Summer rules at {BYS_URL}.</p>
          </div>
          <div>
            <h4>Q What is a my529 scholarship account?</h4>
            <p><strong>A</strong> my529 scholarship accounts are intended for certain qualified education expenses. Qualified withdrawals from scholarship accounts can be used for tuition and mandatory fees, books, required supplies or equipment. Scholarship account funds may be used at any <a href='https://studentaid.gov/fafsa-app/FSCsearch' target='_blank' rel='noopener noreferrer'>eligible educational institution</a>, which is any college, university, or technical college that is eligible to participate in federal student aid programs in the United States or abroad, not just at institutions in Utah. Scholarship accounts are placed in the my529 Target Enrollment Date investment option Enrolled portfolio.</p>

            <p>my529 owns the scholarship account, and the scholarship recipient will be listed as the beneficiary of the scholarship account. Funds are disbursed directly to the beneficiary’s eligible educational institution. Scholarship accounts may not be converted to cash, and nonqualified withdrawals of money may not be made from scholarship accounts. <strong>Funds in my529 scholarship accounts may not be used for K-12 tuition expenses, room and board, computers and related equipment, internet service, qualified education loan repayments or registered apprenticeships.</strong></p>

            <p>my529 reserves the right to request documentation to verify that withdrawn funds were used for certain qualified scholarship expenses. The beneficiary must exhaust the funds in the scholarship account before the beneficiary reaches age 22. After the beneficiary reaches age 22, any remaining scholarship funds will be forfeited.</p>
          </div>
          <div>
            <h4>Q Can recipients add contributions to scholarship accounts?</h4>
            <p><strong>A</strong> No, but recipients can be beneficiaries of separate my529 account(s) that are owned by an adult 18 or older.</p>
          </div>
          <div>
            <h4>Q Can patrons open their own separate my529 account(s)?</h4>
            <p><strong>A</strong> Yes, anyone 18 or older can open an individual my529 account for a beneficiary. Opening a my529 account will not increase the chance of being awarded a scholarship. <strong>There is no purchase necessary to participate.</strong></p>
          </div>
        </div>

        <div>
          <div>
            <h4>Q Is opening a my529 account required to be eligible?</h4>
            <p><strong>A</strong> No, this promotion is open to all Utah residents who meet eligibility requirements. The my529 college savings scholarship account will be owned by my529, with the award recipient named as the beneficiary. Beneficiaries and their families can opt to have interested-party (i.e., view only) access to the account.</p>
          </div>
          <div>
            <h4>Q How and when are the recipients chosen?</h4>
            <p><strong>A</strong> Recipients will be chosen by a random drawing no later than {drawDatePretty}, and notified by telephone or email by {winnerDatePretty}. </p>
          </div>
          <div>
            <h4>Q How many times can a child be entered?</h4>
            <p><strong>A</strong> Parents may enter each child once. Multiple entries will be disqualified.</p>
          </div>
          <div>
            <h4>Q How many children per household can be entered?</h4>
            <p><strong>A</strong> Every eligible child in a household may enter the Book Your Summer Award.</p>
          </div>
          <div>
            <h4>Q Will libraries receive promotional materials? Who is providing the promotional materials?</h4>
            <p><strong>A</strong> my529 will produce and print promotional bookmarks and posters. The State Library will distribute bookmarks and posters to libraries and bookmobiles.</p>
          </div>
          <div>
            <h4>Q When will libraries receive promotional materials?</h4>
            <p><strong>A</strong> By {libraryPromoDatePretty}.</p>
          </div>
          <div>
            <h4>Q How many bookmarks and posters will each library receive?</h4>
            <p><strong>A</strong> The number of bookmarks sent to your library will be based on last year’s summer reading program participation statistics. For systems with multiple branches, materials will be sent to the main library. Each system will then be responsible for distributing materials to branches.</p>
          </div>
          <div>
            <h4>Q What if my library runs out of bookmarks?</h4>
            <p><strong>A</strong> Contact the State Library. You can also refer your patrons to the contest poster. Upon request, digital files for 8.5” x 11” and 11” x 17” posters are available, as well as the digital bookmark files.</p>
          </div>
          <div>
            <h4>Q What information is on the bookmarks?</h4>
            <p><strong>A</strong> The bookmarks reference basic information and a website address ({BYS_URL}) for entry. Refer patrons to the website for more information.</p>
          </div>
          <div>
            <h4>Q Are relatives of public library employees eligible to participate?</h4>
            <p><strong>A</strong> Yes. However, State Library employees and their relatives (i.e., father, mother, child, sibling, stepparent, stepchild, stepsibling, grandparent, grandchild or spouse) are not eligible.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

FAQs.propTypes = {
  startDatePretty: PropTypes.string.isRequired,
  endDatePretty: PropTypes.string.isRequired,
  drawDatePretty: PropTypes.string.isRequired,
  winnerDatePretty: PropTypes.string.isRequired,
  birthDatePretty: PropTypes.string.isRequired,
  currentYear: PropTypes.string.isRequired,
  libraryPromoDatePretty: PropTypes.string.isRequired,
};

export default connect(select, {})(FAQs);
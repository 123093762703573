import colors from '../../components/AppRoot/styles.css';
import { lighten } from '@mui/material/styles';

const appColors = {
  accent: colors['--accent'] || '#FFF', // Appeases the unit test gods' inability to load css
  alternateText: colors['--alternateText'],
  error: colors['--error'],
  primary: colors['--primary'] || '#000', // Appeases the unit test gods' inability to load css
  text: colors['--text'],
  linkText: colors['--linkText'],
};

export default {
  palette: {
    accent: appColors.accent,
    alternateText: appColors.alternateText,
    primary: {
      main: appColors.primary,
      light: lighten(appColors.primary, 0.85),
      dark: appColors.primary,
    },
    secondary: {
      main: appColors.accent
    },
    error: {
      main: appColors.error
    },
    text: {
      main: appColors.text
    },
    linkText: {
      main: appColors.linkText
    },
  },
  typography: {
    fontFamily: [
      'PT Sans',
      'sans-serif',
    ]
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        contained: {
          color: '#fff',
          backgroundColor: appColors.primary,
          '&:hover': {
            backgroundColor: lighten(appColors.primary, 0.4),
          },
        },
        outlined: {
          borderColor: appColors.primary,
        },
      },
    }
  }
};
/*
*
* Constants
*
*/
import React from 'react';

export const BYS_URL = <a href='https://bookyoursummer.com/' target='_blank' rel='noopener noreferrer'>bookyoursummer.com</a>;
export const ALL_DATA_GET = 'src/components/Features/public/ALL_DATA_GET';
export const PHASES = {
  Teaser: 'Teaser',
  Start: 'Start',
  End: 'End',
  Winner: 'Winner',
};

export const BIRTHDATE_FORMAT = 'MMMM D, YYYY';
export const DATE_FORMAT = 'dddd, MMMM D, YYYY';
export const SHORT_DATE_FORMAT = 'MMMM D';

export const DATES = {
  TeaserDate: 'TeaserDate',
  StartDate: 'StartDate',
  LibraryPromotionDate: 'LibraryPromotionDate',
  EndDate: 'EndDate',
  DrawingDate: 'DrawingDate',
  WinnerDate: 'WinnerDate',
  CurrentDate: 'CurrentDate',
};
/*
*
* Navigation Actions
*
*/
import { GETrequest } from 'utils/helpers/api_handler';

import {
  ALL_DATA_GET,
} from './constants';

// TODO: api not working yet

export function getAllData() {
  const response = GETrequest('/default/alldata');
  return {
    type: ALL_DATA_GET,
    payload: response
  };
}
/*
*
* LegalFooter
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { IconBtnTooltip } from '@frontend/common';
import {
  Paper,
  Collapse,
} from '@mui/material';

import './LegalFooter.styles.css';

export const legalVersion = 'show-legal-1.0';


class LegalFooter extends React.Component {

  static propTypes = {
    programDescriptionLink: PropTypes.string,
  };

  state = {
    expanded: JSON.parse(localStorage.getItem(legalVersion)) || JSON.parse(localStorage.getItem(legalVersion)) === null,
  };

  showFooterSet() {
    this.setState({
      expanded: !this.state.expanded
    }, () => {
      localStorage.setItem(legalVersion, this.state.expanded);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.expanded !== nextState.expanded;
  }

  render() {
    return (
      <footer id='legalFooter' className='LegalFooter_footer hideOnPrint'>
        <Paper>
          <div className='LegalFooter_legalContainer'>
            <div className='LegalFooter_headerContainer'
              onClick={() => this.showFooterSet()}
            >
              <h1 className='LegalFooter_header'>Important Legal Notice</h1>
              <IconBtnTooltip
                buttonProps={{ 'aria-expanded': this.state.expanded }}
                icon={this.state.expanded ? 'expand_less' : 'expand_more'}
                iconProps={{ className: 'LegalFooter_arrowIcon' }}
                onClick={() => null} // disable on click here
              />
            </div>
            <Collapse in={this.state.expanded} style={this.state.expanded ? { display: 'block' } : { visibility: 'hidden' }} tabIndex={this.state.expanded ? 0 : -1}> {/* eslint-disable-line */}
              <div className='LegalFooter_legalBody'>
                <p>
                  Investing is an important decision. The investments in your account may vary with market conditions and could lose value. Carefully read the Program Description in its entirety for more information and consider all investment objectives, risks, charges and expenses before investing. For a copy of the Program Description, call 800.418.2551 or visit <a href='https://my529.org' target='_blank' rel='noopener noreferrer'>my529.org</a>.
                </p>
                <p>
                  Investments in my529 are not insured or guaranteed by my529, the Utah Board of Higher Education, the Utah Education Board of Trustees, any other state or federal agency, or any third party. However, Federal Deposit Insurance Corporation (FDIC) insurance is provided for the FDIC-Insured investment option. In addition, my529 offers investment options that are partially insured for the portion of the respective investment option that includes FDIC-insured accounts as an underlying investment.
                </p>
                <p>
                  my529 does not provide legal, financial, investment or tax advice. You should consult your own tax or legal advisor to determine the effect of federal and state tax laws on your particular situation.
                </p>
                <br />
              </div>
            </Collapse>
          </div>
        </Paper>
      </footer>
    );
  }
}

export default LegalFooter;

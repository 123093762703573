/*
*
* Params Reducers
*
*/
import { cloneDeep } from 'lodash';
import { ALL_DATA_GET, DATES } from './constants';
import dayjs from 'dayjs';

const initialState = {
  allDataErrors: [],
  currentPhase: '',
  libraries: [],
  librariesByRegions: [],
  contestDates: {
    [DATES.TeaserDate]: '',
    [DATES.StartDate]: '',
    [DATES.LibraryPromotionDate]: '',
    [DATES.EndDate]: '',
    [DATES.DrawingDate]: '',
    [DATES.WinnerDate]: '',
    [DATES.CurrentDate]: '',
    birthDate: '',
    visitYear: '',
  },
  winners: [],
};

const getDateByName = (dateName, dateList) => dateList.find(date => dateName === date.Name).Date;

function ParamsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case ALL_DATA_GET: {
      // all array items are always in the same order to map values correctly
      if (action.payload.data.Messages.$values.length === 0) {
        // 1. CurrentPhase(),
        newState.currentPhase = action.payload.data.Result.$values[0].$values[0];

        // 2. Libraries(),
        const libraries = action.payload.data.Result.$values[1].$values[0].$values;
        newState.libraries = libraries
          .map(library => ({
            name: library.LibraryName,
            region: library.LibraryRegion,
            libraryId: library.LibraryId,
          }));

        const uniqueRegions = [...new Set(newState.libraries.map(library => library.region))];
        newState.librariesByRegions = uniqueRegions
          .map(region => {
            const librariesByOneRegion = newState.libraries.filter(library => library.region === region).map(library => library.name);
            return {
              region,
              libraries: librariesByOneRegion
            };
          })
          .sort((lib1, lib2) => {
            return lib1.region - lib2.region;
          });

        // 3. ContestDates(),
        // 0 index - Contest dates
        const contestDatesList = action.payload.data.Result.$values[2].$values[0].$values;
        // Teaser
        newState.contestDates[DATES.TeaserDate] = getDateByName(DATES.TeaserDate, contestDatesList);
        // Start
        const startDate = getDateByName(DATES.StartDate, contestDatesList);
        newState.contestDates[DATES.StartDate] = startDate;
        // LibraryPromotion
        newState.contestDates[DATES.LibraryPromotionDate] = getDateByName(DATES.LibraryPromotionDate, contestDatesList);
        // End
        newState.contestDates[DATES.EndDate] = getDateByName(DATES.EndDate, contestDatesList);
        // Drawing 
        newState.contestDates[DATES.DrawingDate] = getDateByName(DATES.DrawingDate, contestDatesList);
        // Winner 
        newState.contestDates[DATES.WinnerDate] = getDateByName(DATES.WinnerDate, contestDatesList);

        // 1 index - Current
        const currentDate = action.payload.data.Result.$values[2].$values[1].Date;
        newState.contestDates[DATES.CurrentDate] = currentDate;

        // calculated dates
        // birthdate
        newState.contestDates.birthDate = dayjs(startDate).subtract(18, 'year');
        // current year
        newState.contestDates.currentYear = dayjs(currentDate).format('YYYY');
        // visit year
        newState.contestDates.visitYear = dayjs(currentDate).isBefore(startDate)
          ?
          dayjs(currentDate)
            .format('YYYY')
          :
          dayjs(startDate)
            .add(1, 'years')
            .format('YYYY');

        // 4. Winners()
        newState.winners = action.payload.data.Result.$values[3].$values[0].$values
          .map(winner => ({
            name: winner.WinnerName,
            libraryName: winner.Library.LibraryName,
            libraryRegion: winner.Library.LibraryRegion,
            year: winner.Year,
          }));
      }
      else {
        newState.allDataErrors = action.payload.data.Messages.$values;
      }
      return newState;
    }

    default:
      return state;
  }

}

export default ParamsReducer;
export function pageNotFoundHit(url) {
  window.ga('send', 'event', {
    eventCategory: 'Page Not Found',
    eventAction: 'navigate',
    eventLabel: url,
  });
}

export function formSubmitted() {
  window.ga('send', 'event', {
    eventCategory: 'Form Submission',
    eventAction: 'submit',
    eventLabel: 'Signup',
  });
}
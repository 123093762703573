export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('bookyoursummer');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  }
  catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  /* eslint-disable */
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('bookyoursummer', serializedState);
  }
  catch (err) {
    throw err;

  }
};
/*
*
* Signup Component
*
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { submitSignupForm } from './actions';
import { formSubmitted } from 'utils/ga/events.js';
import {
  allNotificationsHide,
  DatePicker,
  emailValidate,
  LoadingOverlay,
  Modal,
  notificationShow,
  PhoneNumberInput,
  ZipInput
} from '@frontend/common';
import {
  Autocomplete,
  Button,
  FormHelperText,
  Paper,
  TextField
} from '@mui/material';
import { DATES } from 'components/AppRoot/Navigation/constants';

import './styles.css';

const select = (state) => ({
  libraries: state.params.libraries,
  signupFormErrors: state.signup.signupFormErrors,
  startDate: dayjs(state.params.contestDates[DATES.StartDate]),
  birthDate: dayjs(state.params.contestDates.birthDate),
});

export class Signup extends Component {

  static propTypes = {
    allNotificationsHide: PropTypes.func.isRequired,
    libraries: PropTypes.array.isRequired,
    notificationShow: PropTypes.func.isRequired,
    signupFormErrors: PropTypes.array,
    submitSignupForm: PropTypes.func.isRequired,
    birthDate: PropTypes.object.isRequired,
    startDate: PropTypes.object.isRequired,
  };

  emptyForm = {
    emailAddress: '',
    library: { libraryId: '', name: '' },
    parentFirstName: '',
    parentLastName: '',
    phoneNumber: '',
    postalCode: '',
    readerBirthDate: null,
    readerFirstName: '',
    readerLastName: '',
  };

  state = {
    emailChecked: false,
    errors: [],
    loadingOnSave: false,
    rulesChecked: false,
    signupForm: this.emptyForm,
    successModalOpen: false,
  };

  errorGet = (errorId) => {
    const error = this.state.errors.find(error => error.id === errorId);
    return error ? error.message : '';
  }

  formValidate = () => {
    const { birthDate, startDate } = this.props;

    /* eslint-disable quotes */
    const errors = [];
    const nameRegex = /^[a-zA-Z-',-. ]+$/;
    const {
      signupForm: {
        emailAddress,
        library,
        parentFirstName,
        parentLastName,
        phoneNumber,
        postalCode,
        readerBirthDate,
        readerFirstName,
        readerLastName
      },
      rulesChecked
    } = this.state;
    const requiredField = 'Field is required.';
    const invalidInput = 'Input is invalid.';

    if (!emailValidate(emailAddress)) {
      errors.push({
        id: 'emailAddress',
        message: 'Valid email address is required.'
      });
    }
    if (!library.libraryId) {
      errors.push({
        id: 'library',
        message: 'Library is required.'
      });
    }
    if (!parentFirstName) {
      errors.push({
        id: 'parentFirstName',
        message: requiredField
      });
    }
    if (parentFirstName.search(nameRegex)) {
      errors.push({
        id: 'parentFirstName',
        message: invalidInput
      });
    }
    if (!parentLastName) {
      errors.push({
        id: 'parentLastName',
        message: requiredField
      });
    }
    if (parentLastName.search(nameRegex)) {
      errors.push({
        id: 'parentLastName',
        message: invalidInput
      });
    }
    if (!phoneNumber) {
      errors.push({
        id: 'phoneNumber',
        message: requiredField
      });
    }
    if (!postalCode) {
      errors.push({
        id: 'postalCode',
        message: requiredField
      });
    }
    if (!readerBirthDate) {
      errors.push({
        id: 'readerBirthDate',
        message: requiredField
      });
    }
    else if (!dayjs(readerBirthDate).isValid()) {
      errors.push({
        id: 'readerBirthDate',
        message: invalidInput
      });
    }
    else if (birthDate && startDate && (dayjs(readerBirthDate).isAfter(dayjs(startDate), 'day') || dayjs(readerBirthDate).isBefore(dayjs(birthDate), 'day'))) {
      errors.push({
        id: 'readerBirthDate',
        message: `Reader's birthday must be between ${dayjs(birthDate).format('MMM DD, YYYY')} and ${dayjs(startDate).format('MMM DD, YYYY')}.`
      });
    }
    if (!readerFirstName) {
      errors.push({
        id: 'readerFirstName',
        message: requiredField
      });
    }
    if (readerFirstName.search(nameRegex)) {
      errors.push({
        id: 'readerFirstName',
        message: invalidInput
      });
    }
    if (!readerLastName) {
      errors.push({
        id: 'readerLastName',
        message: requiredField
      });
    }
    if (readerLastName.search(nameRegex)) {
      errors.push({
        id: 'readerLastName',
        message: invalidInput
      });
    }
    if (!rulesChecked) {
      errors.push({
        id: 'rulesChecked',
        message: 'You must agree to the contest rules.'
      });
    }

    this.setState({ errors });
    return errors.length === 0;
  }

  onAddChild = () => {
    this.setState({
      signupForm: {
        ...this.state.signupForm,
        readerFirstName: '',
        readerBirthDate: null
      }
    }, () => this.formValidate());
  }

  onClearForm = () => {
    this.setState({
      errors: [],
      signupForm: this.emptyForm
    });
  }

  onFormSubmit = e => {
    e.preventDefault();
    if (this.formValidate()) {
      const { emailChecked, signupForm } = this.state;
      this.setState({ loadingOnSave: true });

      const mappedForm = {
        ParentFirstName: signupForm.parentFirstName,
        ParentLastName: signupForm.parentLastName,
        ReaderFirstName: signupForm.readerFirstName,
        ReaderLastName: signupForm.readerLastName,
        ReaderBirthdate: dayjs(signupForm.readerBirthDate).toDate(),
        PostalCode: signupForm.postalCode,
        EmailAddress: signupForm.emailAddress,
        DoEmail: emailChecked,
        Phone: signupForm.phoneNumber,
        LibraryId: signupForm.library.libraryId,
      };

      this.props.submitSignupForm(mappedForm)
        .then(() => {
          this.setState({ loadingOnSave: false });
          if (this.props.signupFormErrors.length > 0) {
            const errors = [];
            this.props.signupFormErrors.forEach(error => {
              switch (error.Text) {
                case 'EmailAddress': {
                  errors.push({
                    id: 'emailAddress',
                    message: 'Valid email address is required.'
                  });
                  this.props.notificationShow('Valid email address is required.', 'error');
                  break;
                }
                case 'PostalCode': {
                  errors.push({
                    id: 'postalCode',
                    message: 'Postal code is required.'
                  });
                  this.props.notificationShow('Postal code is required.', 'error');
                  break;
                }
                case 'PhoneNumber': {
                  errors.push({
                    id: 'phoneNumber',
                    message: 'Phone number is required.'
                  });
                  this.props.notificationShow('Phone number is required.', 'error');
                  break;
                }
                default: this.props.notificationShow(error.Text, 'error'); break;
              }
              this.setState({ errors });
            });
          }
          else {
            this.setState({ successModalOpen: true });
            formSubmitted();
          }
        });
    }
  }

  signupFormSet = (key, value) => {
    this.setState({
      errors: [],
      signupForm: {
        ...this.state.signupForm,
        [key]: value
      }
    });
  }

  textFieldsCompose = (fields) => {
    const { loadingOnSave, signupForm } = this.state;
    return fields.map(({ name, label, ...inputProps }) => {
      return (
        <TextField
          disabled={loadingOnSave}
          error={Boolean(this.errorGet(name))}
          fullWidth
          helperText={this.errorGet(name)}
          id={name}
          key={label}
          label={label}
          name={name}
          onChange={e => this.signupFormSet(e.target.name, e.target.value)}
          value={signupForm[name]}
          {...inputProps}
        />
      );
    });
  }

  componentDidMount() {
    this.props.allNotificationsHide();
  }

  render() {
    const { birthDate, startDate, libraries } = this.props;
    const { emailChecked, loadingOnSave, rulesChecked, signupForm, successModalOpen } = this.state;

    return (
      <div className='Signup_container'>
        <Paper elevation={1} className='Signup_card'>
          <div><h1>Sign up for Book Your Summer</h1></div>
          <form onSubmit={this.onFormSubmit} id='signupFormBYS'>
            {this.textFieldsCompose([
              { name: 'parentFirstName', label: "Parent's First Name", inputProps: { 'aria-label': "Parent's First Name must have at least one character", maxLength: '40' } },
              { name: 'parentLastName', label: "Parent's Last Name", inputProps: { 'aria-label': "Parent's Last Name must have at least one character", maxLength: '40' } },
              { name: 'readerFirstName', label: "Reader's First Name", inputProps: { 'aria-label': "Reader's First Name must have at least one character", maxLength: '40' } },
              { name: 'readerLastName', label: "Reader's Last Name", inputProps: { 'aria-label': "Reader's Last Name must have at least one character", maxLength: '40' } },
            ])}

            <DatePicker
              aria-label={`Reader's Birthday must be on or after January 1, ${dayjs(birthDate).year()}.`}
              className='Form_datepicker_field'
              disabled={loadingOnSave}
              error={Boolean(this.errorGet('readerBirthDate'))}
              format='MM/DD/YYYY'
              fullWidth
              helperText={this.errorGet('readerBirthDate')}
              id='readerBirthDate'
              label="Reader's Birthday"
              maxDate={dayjs(startDate)}
              minDate={dayjs(birthDate)}
              name='readerBirthDate'
              onChange={e => { this.signupFormSet('readerBirthDate', e); }} // eslint-disable-line
              openTo='year'
              value={signupForm.readerBirthDate}
            />
            <ZipInput
              aria-label='Postal code must be a five digit number.'
              error={Boolean(this.errorGet('postalCode'))}
              errorText={this.errorGet('postalCode')}
              label='Postal Code'
              name='postalCode'
              onChange={({ value }) => this.signupFormSet('postalCode', value)}
              value={signupForm.postalCode}
            />
            {this.textFieldsCompose([
              { name: 'emailAddress', label: 'Email Address', inputProps: { 'aria-label': 'Email Address must be valid.' } },
            ])}

            <PhoneNumberInput
              aria-label='Phone number must be a ten digit number.'
              error={Boolean(this.errorGet('phoneNumber'))}
              errorText={this.errorGet('phoneNumber')}
              label='Phone Number'
              name='phoneNumber'
              onChange={({ value }) => this.signupFormSet('phoneNumber', value)}
              type='tel'
              value={signupForm.phoneNumber}
            />

            <Autocomplete
              aria-label='Find your library from the list by typing its name'
              disabled={loadingOnSave}
              onChange={(event, library) => this.signupFormSet('library', library)}
              options={libraries}
              value={signupForm.library}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField fullWidth {...params} label='Find your library by typing its name' />}
            />
            {this.errorGet('library') && <FormHelperText error={Boolean(this.errorGet('library'))}>{this.errorGet('library')}</FormHelperText>}

            <label style={{ marginTop: '18px' }}>
              <input
                onChange={() => this.setState({ rulesChecked: !rulesChecked })} checked={rulesChecked} disabled={loadingOnSave}
                id='rulesCheckbox'
                type='checkbox'
              />
              I have read, understand and agree to the contest <Link to='/rules' target='_blank'>rules</Link>
              {this.errorGet('rulesChecked') && <FormHelperText error={Boolean(this.errorGet('rulesChecked'))}>{this.errorGet('rulesChecked')}</FormHelperText>}
            </label>

            <label>
              <input
                onChange={() => this.setState({ emailChecked: !emailChecked })} checked={emailChecked} disabled={loadingOnSave}
                type='checkbox'
              />
              {`I would like to receive an email detailing information about my529's September Make Your Mark scholarship contest and a special promotional match offer from my529.`}
            </label>

            <div className='Signup_buttons' style={{ marginTop: '22px' }}>
              <Button
                disabled={loadingOnSave}
                label='Reset'
                onClick={this.onClearForm}
                type='reset'
                variant='contained'
              >
                Reset
              </Button>
              <LoadingOverlay show={loadingOnSave}>
                <Button
                  color='primary'
                  label='Submit'
                  onClick={this.onFormSubmit}
                  type='submit'
                  variant='contained'
                  disabled={loadingOnSave} /* To fix duplicate keyboard enter since I can't pull in 3.1.27 common */
                >
                  Submit
                </Button>
              </LoadingOverlay>
            </div>

          </form>
        </Paper>

        <Modal
          className='Signup_modal'
          style={{ fontFamily: 'PT Sans' }}
          show={successModalOpen}
          title='Success'
          onCloseModal={() => this.setState({ successModalOpen: false })}
          actionButtons={[
            {
              label: 'Close',
              action: () => {
                this.setState({ successModalOpen: false });
                this.props.history.push('/');
              }
            },
            {
              label: 'Add another child',
              action: () => {
                this.setState({ successModalOpen: false });
                this.onAddChild();
              },
            }
          ]}
          DialogContentProps={{
            style: { fontFamily: 'PT Sans, sans-serif' }
          }}
        >
          Thank you! Your entry has been submitted.
        </Modal>
      </div>
    );
  }
}

export default connect(select, {
  allNotificationsHide,
  notificationShow,
  submitSignupForm
})(Signup);
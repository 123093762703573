/*
*
* Navigation Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import FAQs from 'components/Features/public/FAQs';
import Header from './Header';
import Home from 'components/Features/public/Home';
import PageNotFound from 'components/Features/public/PageNotFound';
import Rules from 'components/Features/public/Rules';
import Sidenav from './Sidenav';
import Signup from 'components/Features/public/Signup';
import { Notifications, LoadingOverlay } from '@frontend/common';
import LegalFooter from './LegalFooter';

import { getAllData } from 'components/AppRoot/Navigation/actions';

import { PHASES } from 'components/AppRoot/Navigation/constants';

import './styles.css';

const select = (state) => ({
  currentPhase: state.params.currentPhase,
});

export class Navigation extends React.Component {
  static propTypes = {
    getAllData: PropTypes.func.isRequired,
    currentPhase: PropTypes.string.isRequired,
  };

  state = {
    showSideNav: false,
    loadingParams: false,
    allDataErrors: [],
  };

  componentDidMount() {
    if (!this.props.currentPhase) {
      this.setState({ loadingParams: true });
      this.props.getAllData()
        .finally(() => this.setState({ loadingParams: false }));
    }
  }

  render() {
    const { currentPhase } = this.props;
    const { loadingParams } = this.state;
    const navLinks = [
      {
        name: 'HOME',
        path: '/',
        component: Home,
        icon: 'home'
      }, {
        name: 'FAQs',
        path: '/faqs',
        component: FAQs,
        icon: 'help_outline'
      }, {
        name: 'RULES',
        path: '/rules',
        component: Rules,
        icon: 'playlist_add_check'
      }, (currentPhase === PHASES.Start && {
        name: 'SIGN UP',
        path: '/signup',
        component: Signup,
        icon: 'person_add'
      }),
      {
        name: 'CONTACT STATE LIBRARY',
        path: 'https://library.utah.gov',
        icon: 'local_library'
      }, {
        name: 'CONTACT my529',
        path: 'https://my529.org/why-choose-my529/contact-us',
        icon: 'account_balance'
      }
    ];

    return (
      <div className='Navigation_featuresContainer'>
        <Header
          navLinks={navLinks}
          openSideNav={() => this.setState({ showSideNav: true })}
          disableButtons={loadingParams}
        />
        <Sidenav
          navLinks={navLinks}
          onClose={() => this.setState({ showSideNav: false })}
          onOpen={() => this.setState({ showSideNav: true })}
          open={this.state.showSideNav}
          disableButtons={loadingParams}
        />
        <Notifications />
        <LoadingOverlay show={loadingParams}>
          <main className='Navigation_featuresChildren'>
            <Switch>
              {navLinks.map(link => link && link.component &&
                <Route
                  component={link.component}
                  exact
                  key={link.path}
                  path={link.path}
                />
              )}
              <PageNotFound />
            </Switch>
          </main>
        </LoadingOverlay>

        <LegalFooter />
      </div>
    );
  }
}

export default withRouter(connect(select, { getAllData })(Navigation));
import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot, } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import rootReducer from './reducers';
import throttle from 'lodash/throttle';
import { saveState, loadState } from 'utils/helpers/state_handler';

import AppRoot from './components/AppRoot';

if (process.env.NODE_ENV !== 'test') {
  require('./utils/ga/analytics.js');
}

const preloadedState = loadState();
export let store = {};

// check if in dev environment
const URL = window.location.host;
const isDevEnv = Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) // eslint-disable-line no-underscore-dangle
  && (URL.includes('localhost') || URL.includes('dev') || URL.includes('qa'));
  
// create store
store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: [promise],
  devTools: isDevEnv, // makes sure devTools are turn off in prod
});

// save store to sessionStorage
store.subscribe(throttle(() => {
  saveState(store.getState());
}), 1000);

const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.NODE_ENV !== 'test') {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppRoot />
      </BrowserRouter>
    </Provider>
  );
}

serviceWorker.unregister();
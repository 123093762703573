/*
*
* Header Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { My529Logo, sizify } from '@frontend/common';
import {
  AppBar,
  Icon,
  IconButton,
  Toolbar
} from '@mui/material';

import './styles.css';

export class Header extends React.Component {

  static propTypes = {
    navLinks: PropTypes.array.isRequired,
    openSideNav: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
    disableButtons: PropTypes.bool.isRequired,
  };

  handleOnHomeClick = () => {
    this.props.history.push('/');
  }

  render() {
    const { disableButtons, openSideNav, size, navLinks } = this.props;
    return (
      <AppBar>
        <Toolbar className='Header_headerContainer'>
          <div onClick={this.handleOnHomeClick} className='Header_homeLogo'>
            <My529Logo color='var(--alternateText)' className='Header_headerImg' />
          </div>
          {size.windowWidth > 1200 ?
            <nav className='Header_linksContainer'>
              {navLinks.map(link => link && (
                link.path.indexOf('https') !== -1 ?
                  <a
                    className='Header_navLink'
                    href={link.path}
                    key={link.path}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {link.name}
                  </a>
                  :
                  !disableButtons && (
                    <NavLink
                      activeStyle={{
                        fontWeight: 'bold',
                        textDecoration: 'underline solid var(--alternateText)'
                      }}
                      className={'Header_navLink'}
                      exact
                      key={link.path}
                      to={link.path}
                    >
                      {link.name}
                    </NavLink>)
              ))}
            </nav>
            :
            <IconButton disabled={disableButtons} onClick={() => openSideNav()} aria-label='Main menu'>
              <Icon className='Header_menuIcon'>menu</Icon>
            </IconButton>
          }
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(sizify(Header));
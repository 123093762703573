import { combineReducers } from '@reduxjs/toolkit';

import Notifications from '@frontend/common/build/components/Notifications/reducer';
import Signup from 'components/Features/public/Signup/reducer';
import Params from 'components/AppRoot/Navigation/reducer';

const rootReducer = combineReducers({
  notifications: Notifications,
  signup: Signup,
  params: Params, 
});

export default rootReducer;
/*
*
* PageNotFound Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Icon,
  Button,
} from '@mui/material';
import { pageNotFoundHit } from 'utils/ga/events.js';
import './styles.css';

export function PageNotFound(props) {
  pageNotFoundHit(window.location.pathname);
  return (
    <div className='PageNotFound_container'>
      <div className='PageNotFound_shrug'>
        {'¯\\_(ツ)_/¯'}
      </div>
      <p className='PageNotFound_message'>
        {"Oops! Looks like you reached a page that doesn't exist."} {/* eslint-disable-line */}
      </p>
      <Button
        variant='contained'
        onClick={() => props.history.push('/')}
      >
        <Icon>keyboard_arrow_left</Icon>
        Back to Home
      </Button>
    </div>
  );
}

PageNotFound.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(PageNotFound);
/*
*
* Signup Actions
*
*/
import { POSTrequest } from 'utils/helpers/api_handler';

import {
  SIGNUP_FORM_SUBMIT,
} from './constants';

export function submitSignupForm(signupForm) {
  const response = POSTrequest('/default/save', signupForm);
  return {
    type: SIGNUP_FORM_SUBMIT,
    payload: response
  };
}